<template>
  <div class="bg-gray-50">
    <nav class="flex container mx-auto px-5 py-5">
      <ul class="flex flex-grow space-x-4">
        <li>
          <a href="/" class="flex items-center space-x-3 text-gray-700 md:mr-5">
            <img src="../../..//src/assets/logo.png" href="/" />
          </a>
        </li>
      </ul>
      <ul class="flex items-center space-x-5">
        <li class="hidden sm:flex">
          <router-link to="/" class="text-slate-950 hover:text-sky-500 text py-1 px-3">Home</router-link>
        </li>
        <li class="hidden sm:flex">
          <router-link to="/renovation" class="text-slate-950 hover:text-sky-500 text py-1 px-3">Renovatiewerken</router-link>
        </li>
        <li class="hidden sm:flex">
          <router-link to="/facade" class="text-slate-950 hover:text-sky-500 text py-1 px-3">Gevelwerken</router-link>
        </li>
        <li class="hidden sm:flex">
          <router-link to="/about" class="text-slate-950 hover:text-sky-500 text py-1 px-3">Over ons</router-link>
        </li>
        <li class="hidden sm:flex">
          <router-link to="/contact" class="text-slate-950 hover:text-sky-500 text py-1 px-3">Contact</router-link>
        </li>
      </ul>
      <ul class="flex sm:hidden" @click="showMenu = !showMenu">
        <li>
          <a href="#" class="flex-none absolute right-0 px-5 z-30">
            <p class="text-slate-950 tracking-wide hover:bg-gray-900 hover:bg-opacity-30 rounded px-2 py-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                class="h-6 w-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7">
                </path>
              </svg>
            </p>
          </a>
          <div class="absolute top-0 right-0 z-10 h-screen w-full bg-black transition" :class="{
            'invisible opacity-0': !showMenu,
            'visible opacity-40': showMenu,
          }"></div>
          <div class="absolute right-0 z-20 w-full px-5 mt-16 transition" :class="{
            'invisible opacity-0': !showMenu,
            'visible opacity-100': showMenu,
          }">
            <ul class="flex flex-col bg-gray-100 w-full rounded shadow-md overflow-hidden">
              <li class="flex">
                <h3 class="flex flex-1 text-gray-600 px-5 py-4">
                  Navigation
                </h3>
              </li>
              <li class="flex border-t">
                <router-link to="/"
                  class="flex flex-1 text-lg text-gray-700 hover:bg-sky-200 px-8 py-4">Home</router-link>
              </li>
              <li class="flex">
                <router-link to="/renovation"
                  class="flex flex-1 text-lg text-gray-700 hover:bg-sky-200 px-8 py-4">Renovatiewerken</router-link>
              </li>
              <li class="flex">
                <router-link to="/facade"
                  class="flex flex-1 text-lg text-gray-700 hover:bg-sky-200 px-8 py-4">Gevelwerken</router-link>
              </li>
              <li class="flex">
                <router-link to="/about"
                  class="flex flex-1 text-lg text-gray-700 hover:bg-sky-200 px-8 py-4">Over ons</router-link>
              </li>
              <li class="flex">
                <router-link to="/contact"
                  class="flex flex-1 text-lg text-gray-700 hover:bg-sky-200 px-8 py-4">Contact</router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const showMenu = ref(false);

</script>

<style scoped>
img {
  height: 35px;
}

.router-link-active {
  color: #2D77BB;
}
</style>