<template>
  <button class="button">
    <slot></slot>
  </button>
</template>

<style scoped>
button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 15rem;
  height: 3rem;
  border-radius: 25px;
  background-color: #55ADFF;
  font-weight: 600;
}

button:hover {
  background-color: #2D77BB;

}
</style>