<template>
  <h2 class="title">
    <slot></slot>
  </h2>
</template>

<script setup>
</script>

<style scoped>
.title {
  margin-top: 1rem;
  text-align: start;
  font-size: 22px;
  width: 75%;
  border-bottom: 3px solid var(--main-blue);
  margin-bottom: 1rem;
}
</style>