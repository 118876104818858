import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import ContactView from "@/views/ContactView.vue";
import FacadeWorkView from "@/views/FacadeWorkView.vue";
import RenovationWorkView from "@/views/RenovationWorkView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/facade",
    name: "facade",
    component: FacadeWorkView,
  },
  {
    path: "/renovation",
    name: "renovation",
    component: RenovationWorkView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
